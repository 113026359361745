@import 'react-awesome-slider/src/core/styles.scss';

.#{$root-element} {
    &__controls__arrow-left,
    &__controls__arrow-right {
        width: 100%;
        height: 20px;

        &:before,
        &:after {
            width: 2px;
            background-color: #fff;
        }
    }
    &__bullets {
        position: absolute;
        bottom: 15px;
        z-index: 99;
        button {
            width: 20px;
            height: 6px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            margin-left: 6px;
            margin-right: 6px;
            -webkit-transition: all .35s ease-in-out;
            -moz-transition: all .35s ease-in-out;
            -ms-transition: all .35s ease-in-out;
            -o-transition: all .35s ease-in-out;
            transition: all .35s ease-in-out;

            &:hover {
                transform: scaleX(1);
                background-color: #5ABC41;
            }
        }

        .#{$root-element}__bullets--active {
            width: 26px;
            transform: scaleX(1.5);
            background-color: #5ABC41;
        }
    }
}

.slick-list {
    padding: 5px 0 !important;
}

.slick-prev, .slick-next {
    width: 40px!important;
    height: 40px !important;
    z-index: 10;
}

.slick-prev:before, .slick-next:before {
    font-size: 40px !important;
    color: #80c12a !important;
    z-index: 10;
}

.slick-prev {
    left: -10px !important;
}

.slick-next {
    right: -10px !important;
}