@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import './override.css';

.cms-grid-filter ul {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 0;
    box-shadow: 0 0 20px #dddcd9;
    background: #fff;
    border-radius: 25px;
    display: inline-block;
}

.cms-grid-filter ul li:first-child {
    margin: 0;
}

.cms-grid-filter ul li:last-child {
    margin: 0;
}

.cms-grid-filter ul li {
    float: left;
    margin: 0 7px;
    padding: 0;
}

.cms-grid-filter ul li a {
    display: block;
    font-size: 15px;
    cursor: pointer;
    text-transform: uppercase;
    padding: 13px 30px;
    border-radius: 25px;
}

.cms-grid-filter ul li a:hover {
    color: #fff !important;
    background-color: #ffc108;
}

.cms-grid-filter ul li .selected {
    color: #fff;
    background-color: #ffc108;
}

.awssld__timer {
    visibility: hidden;
}

.awssld__content {
    height: auto !important;
}

.awssld__container {
    padding: 0 !important;
    height: 250px !important;
}

.txng-logo {
    min-height: 30px;
    min-width: 360px
}

.support-contact-container {
    position: relative;
    width: 60px;
    height: 60px;
}

.left-logo {
    margin-top: 1rem;
}

.card-container {
    display: block !important;
    word-break: break-word;
}

.left-bot-header-title {
    line-height: 18px;
    font-size: 16px;
}

.left-top-header-title {
    font-size: 16px;
}

.header-address {
    font-size: 11px;
}

.bg-header-left {
    background-image: url(https://res.cloudinary.com/dinhtq98/image/upload/v1605866438/Da%20Nang%20Wholesale%20Market/bg-left-2_aebt2x.png);
    background-repeat: no-repeat;
    background-position-y: bottom;
}

.bg-header-right {
    background-image: url(https://res.cloudinary.com/dinhtq98/image/upload/v1605858946/Da%20Nang%20Wholesale%20Market/bg-right-1_gwxcu2.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
}

.align-items-center-nav {
    align-items: center !important;
}

.header-row-container {
    height: 160px;
    position: relative;
}

.search-width {
    width: 200px;
}

.text-success {
    color: #2dce89
}

.text-info {
    color: #11cdef
}

.text-warning {
    color: #ffc108;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.xy-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.tab-content {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.ads-container img {
    max-height: 250px;
    width: 100%;
}

.btn-inline {
    height: 38px;
    width: 64px;
}

.input-group-prepend {
    padding: 0px !important;
}

.fl-right {
    float: right;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

th,
td {
    padding: 5px;
    text-align: left;
}

.active {
    border: unset !important;
    background-color: unset !important;
    border-radius: unset !important;
    font-weight: 700;
}

.active::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width .3s;
    width: 100%;
}

nav ul li {
    padding: 0 4px;
}

nav ul li a {
    display: inline-block !important;
}

nav ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width .3s;
}

nav ul li a:hover::after {
    width: 100%;
}

.navbar-toggler-icon {
    background-image: url(https://res.cloudinary.com/dinhtq98/image/upload/v1604831485/Icons/menu_1_ks0d1d.svg);
}

.navbar-toggler {
    padding-left: 0;
}

a:hover {
    text-decoration: none;
}

.mdi-icon {
    margin-bottom: 3px;
}

.detail-content {
    text-align: justify;
    margin-right: 2rem;
    margin-left: 2rem;
}

.detail-content p {
    text-indent: 20px;
    font-size: 16px;
}

.orderred-list li {
    padding: 10px 0px;
    list-style: decimal !important;
}

.unorderred-list li {
    padding: 5px 0px;
    list-style: circle !important;
}

.detail-content ul {
    padding-left: 20px;
}

.detail-content ol {
    padding-left: 36px;
    padding-right: 36px;
}

.intro-content li {
    color: "#ffc108";
    padding: 0.5rem;
}

.intro-title {
    font-family: 'Dancing Script', cursive;
    font-size: 36px;
}

.image-description {
    color: #2198d3;
    font-style: italic;
}

.main-title {
    font-weight: 800;
    font-size: 20px;
    color: #000;
}

.main-title:hover {
    text-decoration: none;
    color: #00a2ff;
}

.sub-content {
    min-width: 123px;
}

.sub-title {
    color: #000;
}

.sub-title:hover {
    text-decoration: none;
    color:#00a2ff;
}

.sub-image {
    height: 70px;
    min-width: 123px;
    object-fit: cover;
}

.main-short-description {
    text-align: justify;
    font-size: 13px;
}

.company-short-description {
    text-align: justify;
    font-size: 11px;
}

.author-text {
    font-weight: 700;
    font-size: 12px;
    margin-right: 1rem;
}

.time-text {
    font-size: 12px;
    color: #888;
}

.detail-title {
    font-weight: 800;
    font-size: 26px;
}

.wp-block-image figcaption {
    color: #888;
    font-size: .75rem;
    line-height: 1;
    margin: 0 0 20px;
    padding: 8px 20px 0;
    text-align: right;
    max-width: none;
    width: 100%;
}

.aligncenter {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.wp-block-file__button {
    margin-left: 1rem;
    background-color: #1dd1a1;
    color: #fff;
    padding: 6px 8px;
    text-decoration: none;
    border-radius: 18px;
}

.wp-block-file__button:hover {
    background-color: #1dd14c;
    color: #fff;
    text-decoration: none;
}

.input-group-prepend {
    height: 38px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

li {
    list-style: none !important;
}

.bg-dark-green {
    background: #10ac84;
}

.bg-success {
    background: #12bb3e !important;
}

.bg-whitesmoke {
    background-color: #efedee;
}

.border-new {
    border-bottom: 3px solid red;
}

.border-new-event {
    border-bottom: 3px solid #12bb3e;
}

.font-size-xs {
    font-size: 13px !important;
}

hr {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.bg-neutral-success {
    background-color: rgba(79, 183, 121, .15);
    padding: 0.5rem;
}

.border-product {
    border-bottom: 3px solid #ffc107 !important;
}

.bg-vetgetable {
    /* background-image: url('https://res.cloudinary.com/agridential/image/upload/v1600759795/AGDImage/bgopacity_acgkoz.png');
    background-repeat: repeat;
    width: 100vw;
    height: 100vh;
    background-size: contain; */
}

.bg-vegetColor {
    background-color: #f6f5ef !important;
}

.form-control {
    border: none;
    background-color: #f6f5ef !important;
}

.border-oval {
    border-radius: 20px;
}

.bg-light-green {
    background-color: #CEE4D8;
    border: none;
}

.bg-light-green:hover {
    background-color: #027336;
    border: none;
    color: white !important;
}

.bg-light-green-1 {
    background-color: #baec82;
    border: none;
}

.bg-light-green-1:hover {
    background-color: #92c15c;
    border: none;
    color: white !important;
}

.bg-light-green-1:focus {
    background-color: #92c15c;
    border: none;
    color: white !important;
}

.bg-light-green-1:active {
    background-color: #92c15c;
    border: none;
    color: white !important;
}

.btn-basket {
    color: #027336;
}

.btn-basket:hover {
    color: white !important;
}

.noti-badged-btn-success {
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    background-color: #027336;
    width: 15px;
    height: 15px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 2px #fff;
}

.noti-badged-btn-warning {
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    background-color: #fbc658;
    width: 15px;
    height: 15px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 2px #fff;
}

.divider {
    background: #eeeff8;
    height: 1px;
    overflow: hidden;
}

.divider-v {
    position: absolute;
    top: 0;
    height: 100%;
    min-height: 26px;
    right: 0;
    width: 1px;
    background: #eeeff8;
    overflow: hidden;
}

.border-right-nav {
    border-right: 1px #eeeff8 !important;
}

.avatar-frame {
    border-radius: .65rem !important;
    box-shadow: 0 0 0 3px #fff;
    overflow: hidden;
    display: block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    transition: all .2s ease-in-out;
    opacity: 1;
}

.img-avatar {
    width: 100%;
    height: 100%;
    display: block;
}

.img-fit-left {
    height: 100% !important;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.bg-promotions {
    background-image: url('https://res.cloudinary.com/agridential/image/upload/v1600772668/AGDImage/601xx_pbfjqn.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-color: #fcfcfc;
}

.bg-deals {
    background-image: url('https://res.cloudinary.com/agridential/image/upload/v1600844226/AGDImage/2197215_ol98nu.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #fcfcfc;
}

.flex-wrap-content {
    flex-wrap: wrap;
}

.flexCard {
    flex-grow: 1;
}

.flex-stretch-card {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;
}

.flex-stretch-tab-bar {
    display: flex;
    align-items: stretch;
    /* justify-content: center; */
    flex: 0 1 calc(100%/3 - 10px);
    /* max-width: 25%;  */
    margin: 5px;
    cursor: pointer;
}

.flex-stretch-card-bar {
    display: flex;
    align-items: stretch;
    /* justify-content: center; */
    flex: 0 1 calc(100%/6 - 10px);
    /* max-width: 25%;  */
    margin: 5px;
    cursor: pointer;
}

.flex-stretch-card-bar-mobile {
    display: flex;
    align-items: stretch;
    /* justify-content: center; */
    flex: 0 1 calc(100%/5 - 0px);
    /* max-width: 25%;  */
    margin: 0px;
    cursor: pointer;
    justify-content: center;
}

.flex-stretch-card-product {
    display: flex;
    align-items: stretch;
    /* justify-content: center; */
    flex: 0 1 calc(25% - 10px);
    /* max-width: 25%;  */
    margin: 5px;
    cursor: pointer;
}

.flex-stretch-card-product-mobile {
    display: flex;
    align-items: stretch;
    /* justify-content: center; */
    flex: 0 1 calc(50% - 10px);
    /* max-width: 25%;  */
    margin: 5px;
    cursor: pointer;
}

.flex-item-product {
    flex-flow: row wrap;
}

.promotion-title {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 12px;
}

.d-40 {
    height: 40px !important;
    width: 40px !important;
}

.icon-nav {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
}

.animation-zoom {
    transition: transform .2s
}

.animation-zoom:hover {
    transform: scale(1.1);
}

.nav-border-bottom {
    border-bottom: 2px solid #dee2e6;
}

.nav-link-tab.active::after {
    /* border-bottom: 3px solid #027336 !important; */
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #027336;
    height: 4px;
    border-radius: 10px 10px 10px 10px;
}

.nav-link-tab.active {
    font-weight: bold !important;
    color: black !important;
}

.nav-link-tab {
    font-size: 13px !important;
    cursor: pointer;
}

.text-13 {
    font-size: 13px !important;
    color: black !important;
}

.trending-box {
    height: 8px;
    width: 25px;
    background-color: orange;
    border-radius: 10px;
}

.trending-box-success {
    height: 8px;
    width: 25px;
    background-color: #027336;
    border-radius: 10px;
}

.trending-box-warning {
    height: 8px;
    width: 25px;
    background-color: orange;
    border-radius: 10px;
}

.ava-product {
    width: 100%;
    min-height: 180px;
    height: auto;
    min-width: 165px;
    position: relative;
}

.ava-product-mobile {
    height: 100px;
    width: 100px;
}

.img-product {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
}

.label-new {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 25px;
    padding: 10px;
    z-index: 1;
}

.label-sale {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 25px;
    padding: 10px;
    z-index: 1;
}

.info-product-card {
    position: relative;
    /* height: 80px; */
}

.origin-lable {
    /* position: absolute;
    top: 50%; */
    color: #a4a79a;
}

.origin-lable:hover {
    color: #80c12a;
}

.product-lable:hover {
    color: #80c12a;
}

.card-product:hover {
    border: 1px solid #ffc108;
}

.card-product:hover img {
    transform: translate(-50%, -50%) scale(1.1);
    transition: all .2s ease-in-out;
}

.card-manufacturer:hover {
    border: 1px solid #2198d3;
}

.card-blur-product:hover .img-product {
    webkit-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
    opacity: 0.4;
}

.card-blur-product:hover .info-product-card {
    webkit-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
    opacity: 0.4;
}

.card-blur-product:hover .label-new {
    webkit-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
    opacity: 0.4;
}

.card-blur-product:hover .label-sale {
    webkit-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
    opacity: 0.4;
}

.info-inner {
    -webkit-transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    position: absolute;
    width: 100%;
    bottom: 25px;
}

.card-blur-product .box-up {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: none;
}

.card-blur-product:hover .a-size {
    -webkit-transition-delay: 300ms;
    -o-transition-delay: 300ms;
    transition-delay: 300ms;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
}

.btn-eye {
    padding: 0.7rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.card-product .btn-eye {
    background-color: #ffc108;
}

.card-manufacturer .btn-eye {
    background-color: #2198d3;
}

.btn-eye .eye-color {
    color: #fff;
}

.card-product .btn-eye:hover .eye-color {
    color: #ffc108;
}

.card-manufacturer .btn-eye:hover .eye-color {
    color: #2198d3;
}

.btn-eye:hover {
    background-color: #fff;
}

.a-size {
    -webkit-transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    position: absolute;
    width: 100%;
    bottom: -20px;
    font-family: 'PT Sans', sans-serif;
    color: #828282;
    opacity: 0;
    display: flex;
    justify-content: center;
    flex-flow: column;
}

.border-12 {
    border-radius: 12px;
}

.border-12-product {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.d-200 {
    height: 200px;
}

.no-box-shadow {
    box-shadow: none !important;
}

.search-bar-mobile .form-control {
    background-color: white !important;
}

.nav-mobile .nav-link-mobile {
    font-weight: bold;
    /* color: black !important; */
    margin: 5px;
}

.nav-link-mobile-active {
    color: black !important;
}

.nav-bar-mobile-bot {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1000;
    right: 0;
    box-shadow: 0 -8px 20px 10px white
}

.text-deactive-icon {
    color: #dbd9d9;
}

.active-icon-mobile {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    box-shadow: 0px 0px 11px 5px #f6f6f6
}

.font-size-xs {
    font-size: 13px !important;
}

.shadow-navi {
    box-shadow: 0px 0px 11px 5px #fff
}

/* .active-icon-mobile::after{
    content: "";
    box-shadow: 1px 1px 0px 9px #E5F9ED;
    background-color: transparent;
    border-radius: 50%;
} */
.border-info {
    border-bottom: 3px solid #2198d3 !important;
}

.fixed-ad-side-left {
    border: 1px solid #cecece;
    width: 200px;
    position: fixed;
    top: 250px;
    left: 50px;
    z-index: 99;
}

.fixed-ad-side-right {
    border: 1px solid #cecece;
    width: 200px;
    position: fixed;
    top: 250px;
    right: 50px;
    z-index: 99;
}

.fixed-support-contact {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
    background-color: #fff;
    z-index: 99;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.fixed-support-contact::after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
}

.fixed-support-contact:hover {
    transform: translateY(-6px);
}

@keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1;
    }

    100% {
        width: 100px;
        height: 100px;
        opacity: 0;
    }
}

.scroll-area {
    height: 450px;
}

.over-navigation {
    top: unset !important;
    bottom: 35%;
}

.over-footer {
    bottom: 300px;
}

@media(max-width: 1620px) {
    .fixed-ad-side-left {
        width: 12%;
        left: 2%;
    }

    .fixed-ad-side-right {
        width: 12%;
        right: 2%;
    }

    .container {
        max-width: 70%;
    }

    .nav-container {
        max-width: 80%;
    }
}

@media(min-width: 1300px) {
    .flex-stretch-card-product {
        max-width: 23.8%;
    }
}

@media(min-width: 1105px) {
    .content-center {
        justify-content: center !important;
    }
}

@media(max-width: 1105px) {
    .detail-content ol {
        padding-left: 26px;
        padding-right: 26px;
    }

    .left-logo {
        margin-top: 0.75rem;
    }
    
    .fixed-ad-side-left {
        display: none;
    }

    .fixed-ad-side-right {
        display: none;
    }

    .container {
        max-width: 100%;
    }

    .nav-container {
        max-width: 100% !important;
    }

    .align-items-center-nav {
        align-items: unset !important;
    }

    .search-small-scr {
        margin-top: 10px;
    }
}

@media(max-width: 990px) {
    .bg-header-right {
        background-image: none;
    }

    .justify-content {
        justify-content: unset;
    }

    .search-container {
        width: 100%;
    }
}

@media(max-width: 1300px) {
    .nav-container {
        max-width: 100%;
    }
}

@media(max-width: 600px) {
    .detail-content ol {
        padding-left: 16px;
        padding-right: 16px;
    }

    .left-logo {
        margin-top: 0.75rem;
    }

    .left-bot-header-title {
        line-height: 13px;
        font-size: 11px;
    }

    .left-top-header-title {
        font-size: 11px;
    }

    .header-address {
        font-size: 8px;
    }

    .header-row-container {
        height: 120px;
    }

    .nav-group-btn-open {
        position: relative;
    }

    .group-btn-right {
        position: absolute;
        top: 49px;
    }

    .ads-container img {
        max-height: 160px;
    }

    .awssld__container {
        height: 160px !important;
    }

    .main-short-description {
        display: none;
    }

    .mobile-row {
        display: block !important;
    }

    .mobile-inline-btn {
        margin-top: 10px;
    }
}

@media(max-width: 479px) {
    .ads-container img {
        max-height: 150px;
    }

    .awssld__container {
        height: 150px !important;
    }

    .left-logo {
        margin-top: 0.75rem;
    }

    .left-bot-header-title {
        line-height: 11px;
        font-size: 9px;
    }

    .left-top-header-title {
        font-size: 9px;
    }

    .header-address {
        font-size: 8px;
    }

    .search-width {
        width: 170px;
    }

    .ava-product {
        width: fit-content;
        min-height: unset;
        min-width: 130px;
        height: 130px;
    }
}

@media(max-width: 410px) {
    .title-section {
        font-size: 14px;
    }

    .ads-container img {
        max-height: 140px;
    }

    .awssld__container {
        height: 140px !important;
    }

    .left-logo {
        margin-top: 0.6rem;
    }

    .search-width {
        width: 150px;
    }

    .ava-product {
        width: fit-content;
        min-height: unset;
        min-width: 110px;
        height: 110px;
    }

    .left-bot-header-title {
        line-height: 11px;
        font-size: 9px;
    }

    .header-address {
        font-size: 6px;
    }
}

@media(max-width: 370px) {
    .ads-container img {
        max-height: 130px;
    }

    .awssld__container {
        height: 130px !important;
    }

    .ava-product {
        width: fit-content;
        min-height: unset;
        min-width: 100px;
        height: 100px;
    }
}

@media(max-width: 360px) {
    .title-section {
        font-size: 12px;
    }

    .ava-product {
        width: fit-content;
        min-height: unset;
        min-width: 90px;
        height: 90px;
    }
}

@media(max-width: 330px) {
    .title-section {
        font-size: 11px;
    }

    .ava-product {
        width: fit-content;
        min-height: unset;
        min-width: 130px;
        height: 130px;
    }

    .flex-item-product {
        justify-content: center;
    }
}

.btn-info {
    background-color: #11cdef;
    border-color: #11cdef;
    color: #fff;
}

.btn-danger {
    background-color: #f5365c;
    border-color: #f5365c;
    color: #fff;
}

.btn-success {
    background-color: #2dce89;
    border-color: #2dce89;
    color: #fff;
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.header-container {
    max-width: 100%;
    background-image: url(https://res.cloudinary.com/dinhtq98/image/upload/v1606384677/Da%20Nang%20Wholesale%20Market/Asset-2_4x_7_btbi5g.png);
}